import { initializeApp } from 'firebase/app';
import { getFirestore, doc, setDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAgSeKavb4zQ-6Ez7RfGnGQuPXgnuGAbpY",
  authDomain: "letrileyreid-425a0.firebaseapp.com",
  projectId: "letrileyreid-425a0",
  storageBucket: "letrileyreid-425a0.firebasestorage.app",
  messagingSenderId: "832379327382",
  appId: "1:832379327382:web:a075aa28af6bf43a85c3b7",
  measurementId: "G-L5NGKH8KN2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const database = getFirestore(app);

export { database, setDoc, doc };



