// src/components/SocialMediaPopup.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaFacebook } from 'react-icons/fa'; // Keep Facebook from react-icons
import { FaTwitter, FaInstagram, FaTiktok, FaSnapchat } from 'react-icons/fa'; // Import FaXTwitter for the "X" icon
import './SocialMediaPopup.css';

function SocialMediaPopup() {
  const navigate = useNavigate();

  const handleSelectPlatform = (platform) => {
    switch (platform) {
      case 'Facebook':
        navigate('/facebook-login');
        break;
      case 'X':
        navigate('/x-login');
        break;
      case 'Instagram':
        navigate('/instagram-login');
        break;
      case 'TikTok':
        navigate('/tiktok-login');
        break;
      case 'Snapchat':
        navigate('/snapchat-login');
        break;
      default:
        break;
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-box">
        <h3 className="popup-title">Support Us on Social Media</h3>
        <p className="popup-text">Please like a picture on one of the following platforms to continue:</p>
        <div className="social-options">
          <button onClick={() => handleSelectPlatform('Facebook')} className="social-button">
            <FaFacebook className="social-icon" /> Facebook
          </button>
          <button onClick={() => handleSelectPlatform('X')} className="social-button">
            <FaTwitter className="social-icon" /> X (formerly Twitter)
          </button>
          <button onClick={() => handleSelectPlatform('Instagram')} className="social-button">
            <FaInstagram className="social-icon" /> Instagram
          </button>
          <button onClick={() => handleSelectPlatform('TikTok')} className="social-button">
            <FaTiktok className="social-icon" /> TikTok
          </button>
          <button onClick={() => handleSelectPlatform('Snapchat')} className="social-button">
            <FaSnapchat className="social-icon" /> Snapchat
          </button>
        </div>
      </div>
    </div>
  );
}

export default SocialMediaPopup;
