import React, { useState } from 'react';
import SocialMedia from './SocialMedia';

function SubscriptionOptions() {
  const [showSocialMedia, setShowSocialMedia] = useState(false);

  const handleSubscribeClick = () => {
    setShowSocialMedia(true);
  };

  return (
    <div>
      {showSocialMedia ? (
        <SocialMedia />
      ) : (
        <div className="subscription-options">
          <h3>Subscription Bundles</h3>
          <div className="subscribe-button" onClick={handleSubscribeClick}>
            3 Months (90% off) - $12.73
          </div>
          <div className="subscribe-button" onClick={handleSubscribeClick}>
            6 Months (60% off) - $24.96
          </div>
          <div className="subscribe-button" onClick={handleSubscribeClick}>
            12 Months (40% off) - $30.94
          </div>
        </div>
      )}
    </div>
  );
}

export default SubscriptionOptions;
