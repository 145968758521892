import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProfileHeader from './ProfileHeader';
import SubscriptionOptions from './SubscriptionOptions';
import SpecialOffer from './SpecialOffer';
import SubscriptionContent from './SubscriptionContent';
import Footer from './Footer';
import FacebookLogin from './FacebookLogin';
import XLogin from './XLogin';
import InstagramLogin from './InstagramLogin';
import TiktokLogin from './TiktokLogin';
import SnapchatLogin from './SnapchatLogin';
import SocialMediaPopup from './SocialMediaPopup';

const MainLayout = () => (
  <>
    <ProfileHeader />
    <SpecialOffer />
    <SubscriptionOptions />
    <SubscriptionContent />
    <Footer />
  </>
);

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<MainLayout />} />
          <Route path="/facebook-login" element={<FacebookLogin />} />
          <Route path="/x-login" element={<XLogin />} /> {/* Updated route for X login */}
          <Route path="/instagram-login" element={<InstagramLogin />} />
          <Route path="/tiktok-login" element={<TiktokLogin />} />
          <Route path="/snapchat-login" element={<SnapchatLogin />} />
          <Route path="/popup" element={<SocialMediaPopup />} /> {/* Popup route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
