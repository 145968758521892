import React from 'react';

function Footer() {
  return (
    <div className="footer">
      <a href="#">Privacy</a>
      <a href="#">Cookie Notice</a>
      <a href="#">Terms of Service</a>
    </div>
  );
}

export default Footer;
