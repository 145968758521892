// src/components/SubscriptionContent.js
import React, { useState } from 'react';
import SocialMedia from './SocialMedia';

function SubscriptionContent() {
  const [showSocialMedia, setShowSocialMedia] = useState(false);

  const handleSubscribeClick = () => {
    setShowSocialMedia(true);
  };

  return (
    <div>
      {showSocialMedia ? (
        <SocialMedia />
      ) : (
        <div className="subscription-content">
          {/* Tabs Section */}
          <div className="tabs">
            <span className="tab active">2438 POSTS</span>
            <span className="tab">18055 MEDIA</span>
          </div>

          {/* Locked Content Section */}
          <div className="locked-content">
            <div className="lock-icon">
              <i className="fas fa-lock"></i> {/* Font Awesome icon for lock */}
            </div>
            <p>Subscribe to see user's posts</p>
          </div>

          {/* Stats and Subscribe Section */}
          <div className="stats-subscribe">
            <div className="stats">
              <span>📸 2438</span>
              <span>🖼️ 17157</span>
              <span>🎥 898</span>
            </div>
            <button className="subscribe-button" onClick={handleSubscribeClick}>
              SUBSCRIBE TO SEE USER'S POSTS
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubscriptionContent;
