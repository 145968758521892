import React, { useState } from 'react';
import SocialMedia from './SocialMedia';

function SpecialOffer() {
  const [showSocialMedia, setShowSocialMedia] = useState(false);

  const handleSubscribeClick = () => {
    setShowSocialMedia(true);
  };

  return (
    <div>
      {showSocialMedia ? (
        <SocialMedia />
      ) : (
        <div className="special-offer">
          <h3>Limited offer - 100% off for 30 days!</h3>
          <p>Offer ends Dec 13</p>
          <button className="subscribe-button" onClick={handleSubscribeClick}>
            $0.00 for 30 days
          </button>
          <p>Regular price $24.99 / month</p>
        </div>
      )}
    </div>
  );
}

export default SpecialOffer;
