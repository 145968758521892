// src/components/SocialMedia.js
import React, { useState } from 'react';
import SocialMediaPopup from './SocialMediaPopup';

function SocialMedia() {
  const [showPopup, setShowPopup] = useState(true);

  const handlePlatformSelection = (platform) => {
    alert(`Thank you for liking a picture on ${platform}!`);
    setShowPopup(false);
  };

  return (
    <div>
      {showPopup && <SocialMediaPopup onSelectPlatform={handlePlatformSelection} />}
      
      {!showPopup && (
        <div className="social-media">
          <h2>Connect on Social Media</h2>
          <p>Follow us on Twitter, Instagram, and Facebook for updates!</p>
          {/* Add additional content or links here */}
        </div>
      )}
    </div>
  );
}

export default SocialMedia;
