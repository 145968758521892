import React from 'react';
import profilePic from './profile-pic-url.png'; // Replace with actual path
import bannerPic from './banner-pic-url.png'; // Replace with actual path


function ProfileHeader() {
  return (
    <div className="profile-header">
      {/* Banner Image */}
      <img src={bannerPic} alt="Banner" className="banner" />

      {/* Profile Image and User Info */}
      <div className="profile-info">
        <img src={profilePic} alt="Profile" className="profile-pic" />
        <div className="profile-details">
          <h2>Riley Reid <span className="verified">✔️</span></h2>
          <p className="username">@rileyreidx3 · <span className="status">Available now</span></p>
          <p className="bio">
            I’m a naughty pornstar who posts DAILY! From lesbian scenes to gang bangs to dance teases and behind the scenes; you’ll find it all here! 💕💦
            <br />I offer lots of custom content options and I love getting perverted with my fans…
          </p>
          <a href="#" className="more-info">More info</a>
        </div>
      </div>

      {/* Stats Section */}
      <div className="stats">
        <span>📸 17.2K</span>
        <span>🖼️ 898</span>
        <span>🎥 13</span>
        <span>❤️ 3.64M</span>
      </div>
    </div>
  );
}

export default ProfileHeader;

